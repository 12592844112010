// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-booking-confirm-flyttstadning-js": () => import("./../../../src/pages/booking-confirm/flyttstadning.js" /* webpackChunkName: "component---src-pages-booking-confirm-flyttstadning-js" */),
  "component---src-pages-booking-confirm-hemstadning-js": () => import("./../../../src/pages/booking-confirm/hemstadning.js" /* webpackChunkName: "component---src-pages-booking-confirm-hemstadning-js" */),
  "component---src-pages-booking-confirm-kontorsstadning-js": () => import("./../../../src/pages/booking-confirm/kontorsstadning.js" /* webpackChunkName: "component---src-pages-booking-confirm-kontorsstadning-js" */),
  "component---src-pages-booking-confirm-storstadning-js": () => import("./../../../src/pages/booking-confirm/storstadning.js" /* webpackChunkName: "component---src-pages-booking-confirm-storstadning-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-tjanster-flyttstadning-js": () => import("./../../../src/pages/tjanster/flyttstadning.js" /* webpackChunkName: "component---src-pages-tjanster-flyttstadning-js" */),
  "component---src-pages-tjanster-kontorsstadning-js": () => import("./../../../src/pages/tjanster/kontorsstadning.js" /* webpackChunkName: "component---src-pages-tjanster-kontorsstadning-js" */),
  "component---src-pages-tjanster-storstadning-js": () => import("./../../../src/pages/tjanster/storstadning.js" /* webpackChunkName: "component---src-pages-tjanster-storstadning-js" */)
}

